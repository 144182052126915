@mixin spacer($spacers) {
    @each $spacer, $value in $spacers {
        @include media(lg-down) {
            .height-#{$spacer} {
                height: $value;
            }
        }

        @include media(md-up) {
            .height-lg-#{$spacer} {
            height: $value;
            }
        }
    }
}
