.b-video {
	@include g-image_container(_container, aspect-ratio(16, 9));

	&-poster,
	&-object {
		@include g-image_container(_img);

		border: none;
		object-fit: initial;
	}

	&-poster {
		align-items: flex-end;
		display: flex;
		justify-content: center;
		padding: 20px;
	}

	&-poster_picture {
		img {
			@include g-image_container(_img);
		}
	}

	&-poster_button {
		position: relative;
		z-index: 1;
	}

	&.video-ratio {
		@media screen and (max-width: 767px) {
			padding-top: 60%;

			.b-video-object {
				object-fit: fill;
			}
		}


	}
}
.b-video-controls {
	@include media(md-up) {
		opacity: 0;
		transition: opacity 0.3s ease;
	}

	display: flex;
	justify-content: center;

	&::before {
		content: "";
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 15%;
		background: linear-gradient(rgb(255 255 255 / 0%), rgba(255, 255, 255, 0.7));
		transition: opacity 0.3s ease;
		pointer-events: none;
	}

	&-wrapper {
		position: absolute;
		bottom: 5px;
		display: flex;
		width: 95%;

		.button-control {
			border: 1px solid rgb(0, 0, 0);
    		border-radius: 50%;
			height: 30px;
			width: 30px;
			cursor: pointer;
		}

		#play-svg {
			margin-left: 3px;
		}

		.timeline {
			flex: 1;
			display: flex;
			align-items: center;
			border: none;
			padding: 0 10px 0;
			cursor: pointer;

			.bar {
				background: rgb(228, 228, 228, 0.7);
				height: 3px;
				flex: 1;
			}

			.inner{
				background: rgb(185, 181, 181, 0.7);
				width: 0%;
				height: 100%;
			}
		}
	}
}
.b-video:hover {
	.b-video-controls {
		@include media(md-up) {
			opacity: 1;
		}
	}
}

.b-video-mobile {
	@include media(md-up) {
		display: none;
	}
	display: block;
}

.b-video-desktop {
	@include media(md-up) {
		display: block;
	}
	display: none;
}