/*md

# b-promo_info_box

This is type of container for banners that hold only text content. It do not have aspect ratio holder
and it stretched/pulled by text content.

So it could easily hold any amount of text content without issues on any breakpoints.

```html_example
<div class="b-promo_info_box">
	<div class="b-promo_info_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Shop Now. Pay Later. <br/>
			Exclusively for Members
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a class="m-outline b-button" href="$url('Search-Show', 'cgid', 'category-2')$">
				Join
			</a>
		</div>
	</div>
</div>
```

## Big amount of text

```html_example
<div class="b-promo_info_box">
	<div class="b-promo_info_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Shop Now. Pay Later. <br/>
			Exclusively for Members <br/>
			But not
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
			<br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
			<br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a class="m-outline b-button" href="$url('Search-Show', 'cgid', 'category-2')$">
				Join
			</a>
		</div>
	</div>
</div>
```

## Small amount of text

```html_example
<div class="b-promo_info_box">
	<div class="b-promo_info_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Shop Now. Pay Later.
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy.
		</p>
		<div class="b-promo_caption-actions">
			<a class="m-outline b-button" href="$url('Search-Show', 'cgid', 'category-2')$">
				Join
			</a>
		</div>
	</div>
</div>
```

*/

.b-promo_info_box {
	@include g-grid;

	background: $color-info-obj;
	color: $color-info;

	&-caption {
		grid-column: 1 / grid-end;
		grid-row: 1 / 2;
		padding: rh(12 4);
		text-align: center;
	}
}
.new-text-banner {
	.b-promo_info_box-caption {
		padding: 0px 15px;
	}

	&.reduced-margin {
		margin-top: -20px;
		margin-bottom: -20px;
	}
}
