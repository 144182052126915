@use 'sass:math';
.m-full_bleed {
	.b-carousel.b-carousel-pics-view {
		.b-carousel-track {
			.b-product_tile-title,
			.b-product_tile-price,
			.b-product_tile_swatches {
				margin-left: 15px;
			}
		}
	}
}
.b-carousel.b-carousel-pics-view {
	.b-carousel-track {
		@include media(sm) {
			flex-wrap: wrap;
			justify-content: center;
		}
		.b-carousel-item {
			margin-inline-end: 0px;
			max-width: 24.956%;
			min-width: 24.956%;

			@include media(sm) {
				width: calc(50% - 10px);
				margin-bottom: 20px;
				max-width: 49.56%;
				min-width: 49.56%;
			}
		}
	}
}
