.l-section {
	@include g-section_holder;

	margin-bottom: 40px;
	margin-top: 40px;

	@include media(sm) {
		margin-bottom: 30px;
		margin-top: 30px;
	}

	&.m-full_bleed {
		max-width: $global-site-width-max;
		padding-left: 0;
		padding-right: 0;
	}

	// Remove first section top margin.
	// Note! That's a project-specific feature and could be removed if not needed.
	.l-clp > &:first-of-type, // for regular templates
	.l-hp > &:first-of-type,
	.l-clp > div:first-of-type &, // for page designer templates
	.l-hp > div:first-of-type & {
		margin-top: 0;
	}

	.l-hp & { // stylelint-disable-line
		// Since we have more banners than slots we need to align
		// multiple assets into a single slot.
		// Note! That's a boilerplate-specific feature and should be removed if not needed.
		> .b-promo_box ~ .b-promo_box,
		> .b-promo_box ~ .b-promo_info_box, {
			margin-bottom: 40px;
			margin-top: 40px;

			@include media(sm) {
				margin-bottom: 30px;
				margin-top: 30px;
			}
		}
	}
}
