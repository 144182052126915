$spacersValues: (
  0: 0,
  10: 10px,
  20: 20px,
  30: 30px,
  40: 40px,
  50: 50px,
  60: 60px,
  70: 70px,
  80: 80px,
  90: 90px,
  100: 100px,
);

@include spacer($spacersValues);